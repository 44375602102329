import React, { forwardRef, useState } from 'react';
import { HiX, HiChevronUp, HiChevronDown } from 'react-icons/hi';
import * as Dialog from '@radix-ui/react-dialog';

const attributes = [
  {
    id: 1,
    name: 'Security Licence Permission',
    description:
      'Defines permissions related to the licensing security of the Qlik Sense platform. Users with this permission can manage or view licenses.',
  },
  {
    id: 2,
    name: 'Security Export Permission',
    description:
      'Grants the ability to export data from applications, ensuring that users can securely export reports or datasets.',
  },
  {
    id: 3,
    name: 'AdviseInc Platform Stream',
    description:
      'A stream for the AdviseInc platform that allows users to access apps and data specifically tied to the AdviseInc environment.',
  },
  {
    id: 4,
    name: 'AdviseInc Platform',
    description:
      'The central app or collection of apps within the AdviseInc platform, used for overall data analysis and reporting functions.',
  },
  {
    id: 7,
    name: 'Catalogue Check Stream',
    description:
      'A stream focused on managing catalogue checks, likely for procurement or supply chain applications, enabling access to related apps.',
  },
  {
    id: 8,
    name: 'Catalogue Check App (BANES)',
    description:
      'An application specific to BANES (Bath and North East Somerset) for performing catalogue checks within the Catalogue Check Stream.',
  },
  {
    id: 9,
    name: 'Catalogue Check App (Notts)',
    description:
      'An application specific to Nottinghamshire (Notts) for performing catalogue checks within the Catalogue Check Stream.',
  },
  {
    id: 10,
    name: 'Inventory Management Stream',
    description:
      'A stream dedicated to managing inventory data and applications, providing users access to inventory-related insights and reports.',
  },
  {
    id: 11,
    name: 'Inventory Management App (BANES)',
    description:
      'An application within the Inventory Management Stream specific to BANES for tracking and managing inventory data.',
  },
  {
    id: 12,
    name: 'Workplan Stream',
    description:
      'A stream that manages work planning data and apps, allowing access to work-related tasks, schedules, and progress tracking.',
  },
  {
    id: 13,
    name: 'Workplan App (BANES)',
    description:
      'An application within the Workplan Stream specific to BANES for managing work plans, tasks, and schedules.',
  },
  {
    id: 18,
    name: 'Inventory Management App',
    description:
      'A general-purpose application for managing inventory, accessible within the Inventory Management Stream, not specific to a region.',
  },
];

const statuses = [
  {
    id: 1,
    name: 'Blocked Status',
    description:
      'Users with a Blocked Status are flagged in Auth0, which prevents them from accessing the AdviseInc Platform.',
  },
  {
    id: 1,
    name: 'Deleted Status',
    description:
      'Users marked as Deleted have been permanently removed from our systems, revoking all platform access.',
  },
  {
    id: 1,
    name: 'Expired Status',
    description:
      'Users with an Expired Status have lost access due to an expired license. They must renew their license to regain access.',
  },
];

const roles = [
  {
    id: 1,
    name: 'ADMIN',
    description: 'All current permissions as of 10/01/23',
  },
  {
    id: 4,
    name: 'BENCHMARKING',
    description: 'PB equivalent access in Platform',
  },
  {
    id: 8,
    name: 'INVENTORY_MANAGEMENT',
    description: 'Inventory Management module access',
  },
  {
    id: 5,
    name: 'PLATFORM_STANDARD',
    description: 'PD equivalent access in Platform. Requires BENCHMARKING',
  },
  {
    id: 10,
    name: 'EXPORT_PLUS',
    description:
      'Export permission for PLATFORM_PLUS users. Requires PLATFORM_STANDARD and EXPORT_STANDARD',
  },
  {
    id: 6,
    name: 'PLATFORM_PLUS',

    description:
      'PD+ equivalent access in Platform. Requires PLATFORM_STANDARD.',
  },
  {
    id: 9,
    name: 'EXPORT_STANDARD',

    description:
      'Export permission for PLATFORM_STANDARD users. requires BENCHMARKING.',
  },
  {
    id: 16,
    name: 'WORKPLAN',
    description: 'Denotes Workplan app user. Has no effect in Platform.',
  },
  {
    id: 7,
    name: 'CATALOGUE_CHECK_BANES',
    description: 'Catalogue Check module access',
  },
  {
    id: 17,
    name: 'WORKPLAN_PLUS',
    description:
      'Denotes Workplan specific to sheffields app user. includes self service analytics',
  },
  {
    id: 18,
    name: 'WORKPLAN_STANDARD',
    description:
      'Denotes Workplan specific to sheffields app user. Excludes self service analytics',
  },
  {
    id: 19,
    name: 'SUPERUSER_CUSTOM',
    description: 'Test role for additional custom access',
  },
  {
    id: 20,
    name: 'SUPERUSER_GENERAL',
    description: 'Overall superuser role',
  },
  {
    id: 21,
    name: 'SUPERUSER_ROUTE_SWITCH',
    description: 'Route switch superuser access',
  },
  {
    id: 22,
    name: 'SUPERUSER_BENCHMARK',
    description: 'Benchmark superuser access',
  },
  {
    id: 23,
    name: 'SUPERUSER_PII',
    description:
      'This role is a specific role to allow certain users access to the PII tab',
  },
];

const Section = forwardRef(({ title, items, renderItem }, ref) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="mb-6" ref={ref}>
      <button
        type="button"
        className="flex items-center justify-between w-full p-4 bg-gray-100 rounded-lg focus:outline-none hover:bg-gray-200 transition-colors duration-200"
        onClick={() => setIsOpen(!isOpen)}
        aria-expanded={isOpen}>
        <h2 className="text-lg font-semibold text-gray-800">{title}</h2>
        {isOpen ? (
          <HiChevronUp className="w-6 h-6 text-gray-600" />
        ) : (
          <HiChevronDown className="w-6 h-6 text-gray-600" />
        )}
      </button>
      {isOpen && <div className="mt-4 space-y-4">{items.map(renderItem)}</div>}
    </div>
  );
});

const Glossary = forwardRef(({ setShowModal }, ref) => (
  <Dialog.Root open onOpenChange={setShowModal}>
    <Dialog.Portal>
      <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-30" />
      <Dialog.Content
        // className="fixed inset-0 w-[90vw] h-[90vh] bg-white p-6 rounded-lg shadow-lg focus:outline-none flex flex-col"
        className="fixed inset-0 bg-white p-6 rounded-lg shadow-lg focus:outline-none flex flex-col m-auto max-w-[90vw] max-h-[90vh]"
        ref={ref}>
        <div className="flex items-start justify-between p-5 border-b border-solid border-platform-primary-grey-200 rounded-t">
          <div className="text-3xl font-bold text-platform-primary-grey-800">
            Glossary
          </div>
          <Dialog.Close asChild>
            <button
              type="button"
              aria-label="close-modal-button"
              className="border-2 rounded-md p-2 font-bold bg-platform-cta-error-800 border-platform-cta-error-800 text-white hover:bg-white hover:text-platform-cta-error-800"
              onClick={() => setShowModal(false)}>
              <HiX />
            </button>
          </Dialog.Close>
        </div>

        {/* Content */}
        {/* <div className="relative p-6 flex-auto max-h-[65vh] overflow-y-auto"> */}
        <div className="flex-grow overflow-y-auto p-6">
          <Section
            title="Application Access"
            items={attributes}
            renderItem={(attr) => (
              <div
                key={attr.id}
                className="bg-white p-4 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-200">
                <h3 className="font-semibold text-lg text-gray-800 mb-2">
                  {attr.name}
                </h3>
                <p className="text-gray-600">
                  <span className="font-medium">description:</span>{' '}
                  {attr.description}
                </p>
              </div>
            )}
          />

          <Section
            title="Roles"
            items={roles}
            renderItem={(role) => (
              <div
                key={role.id}
                className="bg-white p-4 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-200">
                <h3 className="font-semibold text-lg text-gray-800 mb-2">
                  {role.name}
                </h3>
                <p className="text-gray-600">
                  <span className="font-medium">Description:</span>{' '}
                  {role.description}
                </p>
              </div>
            )}
          />
          <Section
            title="User status"
            items={statuses}
            renderItem={(status) => (
              <div
                key={status.id}
                className="bg-white p-4 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-200">
                <h3 className="font-semibold text-lg text-gray-800 mb-2">
                  {status.name}
                </h3>
                <p className="text-gray-600">
                  <span className="font-medium">Description:</span>{' '}
                  {status.description}
                </p>
              </div>
            )}
          />
        </div>

        {/* Footer */}
        <div className="p-2 border-t border-solid border-platform-primary-grey-200 rounded-b">
          <div className="flex justify-end my-2">
            <button
              className="mx-1 bg-platform-cta-info-800 text-black text-sm font-bold px-4 py-1 rounded-full hover:bg-white hover:text-platform-cta-info-800 hover:border-platform-cta-info-800 border-1"
              type="button"
              aria-label="close-button"
              onClick={() => setShowModal(false)}>
              Close
            </button>
          </div>
        </div>
      </Dialog.Content>
    </Dialog.Portal>
  </Dialog.Root>
));

export default Glossary;
