import React, { useEffect, useState, Suspense } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { Navigate, Route, Routes } from 'react-router-dom';
// import CryptoJS from 'crypto-js';
import { baseUrl, headers, validAdviseIncEmail } from './lookups';
import Loader from '../components/loader/Loader';
import Users from '../features/users/Users';
import Logs from '../features/logs/Logs';
import Welcome from '../features/welcome/Welcome';
import PageLayout from '../components/PageLayout';
import Home from '../features/home/Home';

function App() {
  const { isLoading, isAuthenticated, user } = useAuth0();

  const [userData, setUserData] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [decryptedData, setDecryptedData] = useState('');

  useEffect(() => {
    if (user) {
      const auth0Id = user.sub.split('|')[1];
      axios
        .get(`${baseUrl}/user-service/users?auth0_id=auth0|${auth0Id}`, {
          headers,
        })
        .then((response) => {
          setUserData(response.data);
        });
    }
  }, [user]);

  // useEffect(() => {
  //   if ('permissions' in userData) {
  //     const encryptedData = userData.permissions.data;
  //     const base64Key = userData.permissions.key;
  //     const decryptData = () => {
  //       const key = CryptoJS.enc.Base64.parse(base64Key);
  //       const dataBytes = CryptoJS.enc.Base64.parse(encryptedData);
  //       const iv = CryptoJS.lib.WordArray.create(dataBytes.words.slice(0, 4));
  //       const ciphertext = CryptoJS.lib.WordArray.create(
  //         dataBytes.words.slice(4),
  //       );
  //       const decryptedBytes = CryptoJS.AES.decrypt({ ciphertext }, key, {
  //         iv,
  //       });
  //       const decryptedText = CryptoJS.enc.Utf8.stringify(decryptedBytes);
  //       // console.log(decryptedText);
  //       setDecryptedData(JSON.parse(decryptedText));
  //     };
  //     decryptData();
  //   }
  // }, [userData]);

  if (isLoading) {
    return <Loader fullscreen />;
  }
  return (
    <Suspense fallback={<Loader fullscreen />}>
      <Routes>
        <Route index element={<Welcome />} />
        <Route
          path="/"
          element={
            <PageLayout admin={`${userData.firstname} ${userData.surname}`} />
          }>
          <Route
            path="/home"
            element={
              isAuthenticated && validAdviseIncEmail.test(user.email) ? (
                <Home />
              ) : (
                <Navigate to="/" />
              )
            }
          />
          <Route
            path="/users"
            element={
              isAuthenticated && validAdviseIncEmail.test(user.email) ? (
                <Users admin={`${userData.firstname} ${userData.surname}`} />
              ) : (
                <Navigate to="/" />
              )
            }
          />
          <Route
            path="/all-users"
            element={
              isAuthenticated && validAdviseIncEmail.test(user.email) ? (
                <Users admin={`${userData.firstname} ${userData.surname}`} />
              ) : (
                <Navigate to="/" />
              )
            }
          />
          <Route
            path="/users-to-activate"
            element={
              isAuthenticated && validAdviseIncEmail.test(user.email) ? (
                <Users admin={`${userData.firstname} ${userData.surname}`} />
              ) : (
                <Navigate to="/" />
              )
            }
          />
          <Route
            path="/new-users"
            element={
              isAuthenticated && validAdviseIncEmail.test(user.email) ? (
                <Users admin={`${userData.firstname} ${userData.surname}`} />
              ) : (
                <Navigate to="/" />
              )
            }
          />
          <Route
            path="/awaiting-approval"
            element={
              isAuthenticated && validAdviseIncEmail.test(user.email) ? (
                <Users admin={`${userData.firstname} ${userData.surname}`} />
              ) : (
                <Navigate to="/" />
              )
            }
          />
          <Route
            path="/rejected-users"
            element={
              isAuthenticated && validAdviseIncEmail.test(user.email) ? (
                <Users admin={`${userData.firstname} ${userData.surname}`} />
              ) : (
                <Navigate to="/" />
              )
            }
          />
          <Route
            path="/blocked-users"
            element={
              isAuthenticated && validAdviseIncEmail.test(user.email) ? (
                <Users admin={`${userData.firstname} ${userData.surname}`} />
              ) : (
                <Navigate to="/" />
              )
            }
          />
          <Route
            path="/active-users"
            element={
              isAuthenticated && validAdviseIncEmail.test(user.email) ? (
                <Users admin={`${userData.firstname} ${userData.surname}`} />
              ) : (
                <Navigate to="/" />
              )
            }
          />
          <Route
            path="/logs"
            element={
              isAuthenticated && validAdviseIncEmail.test(user.email) ? (
                <Logs />
              ) : (
                <Navigate to="/" />
              )
            }
          />
        </Route>
      </Routes>
    </Suspense>
  );
}

export default App;
