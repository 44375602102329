import React from 'react';
import * as Tooltip from '@radix-ui/react-tooltip';
import * as XLSX from 'xlsx';
import { HiDownload } from 'react-icons/hi';

export default function ExportButton({ users }) {
  console.log('users', users);
  const fileName = `Users_Export_${new Date()
    .toISOString()
    .split('T')[0]
    .replace(/-/g, '')}`;
  const fileExtension = '.xlsx';

  const handleExcelExport = async () => {
    const filteredData = users
      .getFilteredRowModel()
      .rows.map((row) => row.original);

    const worksheet = XLSX.utils.json_to_sheet(filteredData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Users');
    XLSX.writeFile(workbook, fileName + fileExtension, { compression: true });
  };

  return (
    <Tooltip.Provider>
      <Tooltip.Root>
        <Tooltip.Trigger asChild>
          <button
            aria-label="export-users-button"
            className="mx-1 my-0.5 px-1 py-1 align-middle items-center text-sm justify-between rounded-full border border-platform-primary-grey-200 bg-white disabled:bg-platform-secondary-grey-200 font-medium text-platform-primary-grey-400 hover:bg-platform-ainc-grey-400"
            type="button"
            onClick={handleExcelExport}>
            <HiDownload className="text-xl" />
          </button>
        </Tooltip.Trigger>
        <Tooltip.Portal>
          <Tooltip.Content
            side="bottom"
            className="rounded-full bg-platform-primary-grey-800 text-white text-xs px-2 py-1">
            Export Users
            <Tooltip.Arrow className="fill-platform-primary-grey-800" />
          </Tooltip.Content>
        </Tooltip.Portal>
      </Tooltip.Root>
    </Tooltip.Provider>
  );
}
