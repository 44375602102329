import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
  useRef,
} from 'react';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import { baseUrl, headers } from './lookups';

const UserContext = createContext(null);

export function UserContextProvider({ children }) {
  const [users, setUsers] = useState([]);
  const [usersToActivate, setUsersToActivate] = useState([]);
  const [preRegUsers, setPreRegUsers] = useState([]);
  const [roles, setRoles] = useState([]);
  const [trusts, setTrusts] = useState([]);
  const [groups, setGroups] = useState([]);
  const [mergedUsers, setMergedUsers] = useState([]);
  const [organisationDefaults, setOrganisationDefaults] = useState([]);
  const rolesRef = useRef([]);
  const { user } = useAuth0();
  useEffect(() => {
    axios
      .get(
        `${baseUrl}/user-service/users/account-details?auth0_id=${user.sub}`,
        { headers },
      )
      .then((response) => {
        setUsers(response.data);
      });
    axios
      .get(`${baseUrl}/user-service/register-interest`, { headers })
      .then((response) => {
        setPreRegUsers(response.data);
      });
    axios
      .get(`${baseUrl}/user-service/users-to-activate`, {
        headers,
        params: {
          interval_days: 2,
        },
      })
      .then((response) => {
        console.log(
          response?.data?.map((userToActivate) => ({
            ...userToActivate,
            ...userToActivate?.user_data,
            ...userToActivate?.user_data?.preferences,
            status: 'awaiting activation',
            source: 'pre_reg_db',
          })),
        );
        setUsersToActivate(
          response?.data?.map((userToActivate) => ({
            ...userToActivate,
            ...userToActivate?.user_data,
            ...userToActivate?.user_data?.preferences,
            status: 'awaiting activation',
            source: 'pre_reg_db',
          })),
        );
      });
    axios.get(`${baseUrl}/user-service/roles`, { headers }).then((response) => {
      rolesRef.current = response.data.map((role) => ({
        ...role,
        value: role.name,
        label: role.name,
      }));
      setRoles(rolesRef.current);
    });
    axios
      .get(`${baseUrl}/user-service/selections/group-orgs`, { headers })
      .then((response) => {
        const trustsTable = [];
        const groupsTable = [];
        response.data.forEach((item, trustIndex) => {
          trustsTable.push({
            trust: item.trust,
            id: trustIndex,
            value: item.trust,
            label: item.trust,
          });

          item.groups.forEach((group) => {
            const existingGroup = groupsTable.find(
              (g) =>
                g.group_name === group.group_name && g.trust === item.trust,
            );
            if (!existingGroup) {
              groupsTable.push({
                group_name: group.group_name,
                trust: item.trust,
                id: trustIndex,
                value: group.group_name,
                label: group.group_name,
              });
            }
          });
        });
        setTrusts(trustsTable);
        setGroups(groupsTable);
      });
    axios
      .get(`${baseUrl}/user-service/users/organisation-defaults`, { headers })
      .then((response) => {
        setOrganisationDefaults(response.data);
      });
  }, [user.sub]);

  useEffect(() => {
    const deduplicateUserData = (preRegUser, usersList, allUsers) => {
      const userIndex = usersList.findIndex(
        ({ email }) =>
          email.toLowerCase() === preRegUser[1].email.toLowerCase(),
      );
      function transformTrust(trust) {
        const parts = trust.split(' | ');
        return parts.length === 2 ? `${parts[0]} (${parts[1]})` : trust;
      }

      if (userIndex !== -1) {
        usersList[userIndex] = { ...usersList[userIndex], ...preRegUser[1] };
      } else {
        preRegUser[1].auth0_id = preRegUser[1]?.auth0_id ?? '';
        preRegUser[1].firstname = preRegUser[1].first_name;
        preRegUser[1].primary_trust = transformTrust(
          preRegUser[1].organisation,
        );
        preRegUser[1].blocked = false;
        preRegUser[1].start_date = preRegUser[1]?.user_data?.start_date ?? null;
        preRegUser[1].end_date = preRegUser[1]?.user_data?.end_date ?? null;
        preRegUser[1].primary_group =
          preRegUser[1]?.user_data?.primary_group ?? ''; // Needs populating when Not Yet Approved
        preRegUser[1].email_verification_sent = null;
        preRegUser[1].locked_to_group =
          preRegUser[1]?.user_data?.locked_to_group ?? false;
        preRegUser[1].hide_kpi = preRegUser[1]?.user_data?.hide_kpi ?? false;
        preRegUser[1].notes = preRegUser[1]?.user_data?.notes ?? null;
        preRegUser[1].sense_attributes =
          preRegUser[1]?.user_data?.sense_attributes ?? [];
        preRegUser[1].roles = preRegUser[1]?.user_data?.roles ?? [];
        preRegUser[1].default_org =
          preRegUser[1]?.user_data?.preferences?.default_org ?? ''; // Needs populating when Not Yet Approved
        preRegUser[1].default_landing_page =
          preRegUser[1]?.user_data?.preferences?.default_landing_page ?? ''; // Needs populating when Not Yet Approved
        preRegUser[1].app_type =
          preRegUser[1]?.user_data?.preferences?.app_type ?? ''; // Needs populating when Not Yet Approved
        preRegUser[1].nickname = preRegUser[1]?.user_data?.nickname ?? '';
        preRegUser[1].profile_picture =
          preRegUser[1]?.user_data?.preferences?.profile_picture ?? '';
        preRegUser[1].user_id = '';
        preRegUser[1].last_login = null;
        preRegUser[1].last_ip = '';
        preRegUser[1].logins_count = 0;
        allUsers.push({ ...preRegUser[1], source: 'pre_reg_db' });
      }
    };

    let allUsers = [...users].map((userData) => ({
      ...userData,
      source: 'db',
    }));

    Object.entries(preRegUsers).forEach((preRegUser) => {
      deduplicateUserData(preRegUser, users, allUsers);
    });

    const preRegKeys = [
      'date_requested',
      'requested_by',
      'other',
      'requested_by_email',
      'requested_by_job_title',
      'crm_code',
      'crm_name',
      'applications_subscription',
      'lifecycle_stage',
      'lifecycle_status',
      'subscription_status',
      'subscription_types',
      'approval_requested',
      'approval_granted',
      'date_approval_requested',
      'date_approval_granted',
      'reason_denied',
      'approver',
      'date_approval_denied',
    ];

    // Populate all keys for all items
    allUsers = allUsers.map((allUser) => {
      let status;
      if (allUser.source === 'db') {
        status = allUser.blocked ? 'blocked' : 'active';
      } else {
        status = allUser.approval_granted
          ? 'awaiting activation'
          : allUser.date_approval_denied !== null
          ? 'rejected'
          : allUser.approval_requested
          ? 'awaiting approval'
          : 'new';
      }
      allUser.status = status; // Add status to the user object
      preRegKeys.forEach((key) => {
        if (!Object.prototype.hasOwnProperty.call(allUser, key)) {
          allUser[key] = null;
        }
      });
      return allUser;
    });

    // Define the order of statuses
    const statusOrder = [
      'new',
      'awaiting approval',
      'awaiting activation',
      'rejected',
      'blocked',
      'active',
    ];

    const sortedUsers = [...allUsers].sort(
      (a, b) => statusOrder.indexOf(a.status) - statusOrder.indexOf(b.status),
    );
    setMergedUsers(sortedUsers);
  }, [users, preRegUsers]);

  const userContextValues = useMemo(
    () => ({
      users,
      setUsers,
      preRegUsers,
      usersToActivate,
      roles,
      mergedUsers,
      trusts,
      groups,
      organisationDefaults,
    }),
    [
      users,
      preRegUsers,
      usersToActivate,
      roles,
      mergedUsers,
      trusts,
      groups,
      organisationDefaults,
    ],
  );

  return (
    <UserContext.Provider
      // Add required values to the value prop within an object (my preference)
      value={userContextValues}>
      {children}
    </UserContext.Provider>
  );
}

// Create a hook to use the UserContext, this is a Kent C. Dodds pattern
export function useUsersAPI() {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error('Context must be used within a Provider');
  }
  return context;
}
