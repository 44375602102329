import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import { auth0Variables } from '../app/lookups';

function Auth0ProviderWithHistory({ children }) {
  const history = useNavigate();
  const domain = auth0Variables.auth0Domain;
  const clientId = auth0Variables.auth0ClientId;
  const conn = auth0Variables.connectionName;

  const onRedirectCallback = (appState) => {
    history(appState?.targetUrl || '/all-users');
  };

  return (
    <Auth0Provider
      connection={conn}
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: `https://${domain}/api/v2/`,
      }}
      scope="read:current_user update:current_user_metadata"
      onRedirectCallback={onRedirectCallback}>
      {children}
    </Auth0Provider>
  );
}

export default Auth0ProviderWithHistory;
