import React, { useState } from 'react';
import UsersTable from './UsersTable';
import PreRegistrationTable from './PreRegistrationTable';
import { UserContextProvider } from '../../app/userContext';
import ApproveUsersTable from './ApproveUsersTable';
import MainTable from './MainTable';

export default function Users({ admin }) {
  const [selectedUsers, setSelectedUsers] = useState([]);
  let table;
  switch (window.location.pathname) {
    case '/users':
      table = (
        <div className="h-full">
          <UsersTable
            admin={admin}
            selectedUsers={selectedUsers}
            setSelectedUsers={setSelectedUsers}
          />
        </div>
      );
      break;
    case '/pre-registration':
      table = (
        <div className="h-full">
          <PreRegistrationTable admin={admin} />
        </div>
      );
      break;
    case '/all-users':
      table = (
        <div className="h-full">
          <MainTable
            admin={admin}
            selectedUsers={selectedUsers}
            setSelectedUsers={setSelectedUsers}
          />
        </div>
      );
      break;
    case '/users-to-activate':
      table = (
        <div className="h-full">
          <ApproveUsersTable
            admin={admin}
            selectedUsers={selectedUsers}
            setSelectedUsers={setSelectedUsers}
          />
        </div>
      );
      break;
    case '/new-users':
      table = (
        <div className="h-full">
          <MainTable
            admin={admin}
            selectedUsers={selectedUsers}
            setSelectedUsers={setSelectedUsers}
            statusFilter="new"
          />
        </div>
      );
      break;
    case '/awaiting-approval':
      table = (
        <div className="h-full">
          <MainTable
            admin={admin}
            selectedUsers={selectedUsers}
            setSelectedUsers={setSelectedUsers}
            statusFilter="awaiting approval"
          />
        </div>
      );
      break;
    case '/rejected-users':
      table = (
        <div className="h-full">
          <MainTable
            admin={admin}
            selectedUsers={selectedUsers}
            setSelectedUsers={setSelectedUsers}
            statusFilter="rejected"
          />
        </div>
      );
      break;
    case '/blocked-users':
      table = (
        <div className="h-full">
          <MainTable
            admin={admin}
            selectedUsers={selectedUsers}
            setSelectedUsers={setSelectedUsers}
            statusFilter="blocked"
          />
        </div>
      );
      break;
    case '/active-users':
      table = (
        <div className="h-full">
          <MainTable
            admin={admin}
            selectedUsers={selectedUsers}
            setSelectedUsers={setSelectedUsers}
            statusFilter="active"
          />
        </div>
      );
      break;
    default:
      table = null;
  }

  return (
    <div className="h-full">
      <UserContextProvider>{table}</UserContextProvider>
    </div>
  );
}
